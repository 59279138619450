/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import styles from './home.module.scss';
import { PulseLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import { APIURI_PROD, MOLLIETEST } from './config';
import { useSearchParams } from 'react-router-dom';

const Home = () => {
  const [paymentDetails, setPaymentDetails] = useState();
  const [fetching, setFetching] = useState(false);
  const [paying, setPaying] = useState(false);
  const navigate = useNavigate();
  let { id } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  let [paymentid, setPaymentid] = useState(searchParams.get('paymentid'));

  const loadPaymentDetails = async (id) => {
    setFetching(true);
    const url = `${APIURI_PROD}/mollie/details/${id}`;
    const res = await fetch(url);
    const data = await res.json();
    setFetching(false);
    if (data?.success && data?.payment && !data?.payment.completed) {
      setPaymentDetails(data.payment);
    } else {
      Swal.fire(
        'Betaling. Payment.',
        'We konden geen rit vinden waarvoor een betaling nodig is. We could not find an order awaiting payment.',
        'info'
      );
    }
  };

  const checkForPaymentStatus = () => {
    console.log('checking for the status');
    setPaying(true);
    let checkCounter = 0;
    const checkForPayment = () => {
      setTimeout(async () => {
        const res = await fetch(
          `${APIURI_PROD}/mollie/paymentstatus/${paymentid}`
        );
        const data = await res.json();
        const payment = data?.payment || null;
        if (payment?.mollie?.status) {
          const status = payment.mollie.status;
          if (status === 'open') {
            checkCounter++;
            if (checkCounter < 10) {
              checkForPayment();
            } else {
              setPaying(false);
              navigate(`/${paymentid}`);
              Swal.fire(
                'Betaling mislukt. Payment Failed.',
                'De betaling is niet gelukt. Probeer opnieuw. Payment failed. Try again please.',
                'info'
              );
            }
          } else {
            setPaying(false);
            const paymentEnd = ['canceled', 'failed', 'expired'];
            if (paymentEnd.indexOf(status) > -1) {
              Swal.fire(
                'Betaling mislukt. Payment Failed.',
                'De betaling is niet gelukt. Probeer opnieuw. Payment failed. Try again please.',
                'info'
              );
              navigate(`/${paymentid}`);
            } else {
              if (status === 'paid') {
                return navigate('/paymentcomplete?id=' + paymentid);
              }
            }
          }
        } else {
          Swal.fire(
            'Betaling mislukt. Payment Failed.',
            'De betaling is niet gelukt. Probeer opnieuw. Payment failed. Try again please.',
            'info'
          );
          setPaying(false);
        }
      }, 3000);
    };
    checkForPayment();
  };

  useEffect(() => {
    console.log(paymentid);
    console.log(id);
    if (id) {
      loadPaymentDetails(id);
    }
    if (paymentid?.length) {
      checkForPaymentStatus();
    }
  }, [id, paymentid]);

  const startPayment = async (e) => {
    e.preventDefault();
    setPaying(true);
    const url = `${APIURI_PROD}/mollie/startpayment`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id,
        test: MOLLIETEST,
      }),
    });
    const data = await res.json();
    console.log(data);
    if (!data || !data.success || !data.payment) {
      setPaying(false);
      let errorText =
        'Probleem bij aanmaken betaling via Mollie. Problem creating Mollie payment.';
      if (data?.error) {
        switch (data.error) {
          case 'ALREADYPAID':
            errorText =
              'Deze rit is reeds betaald. This order is already paid.';
            break;
          case 'NOORDER':
            errorText =
              'Deze rit moet niet meer betaald worden. This order is not awaiting payment.';
            break;
          default:
            break;
        }
      }
      return Swal.fire('Probleem. Problem.', errorText, 'error');
    } else {
      const { mollie } = data.payment;
      if (mollie && mollie['_links'] && mollie['_links'].checkout) {
        window.location.href = mollie['_links'].checkout.href;
      } else {
        setPaying(false);
        return Swal.fire(
          'Probleem. Problem.',
          'Probleem bij aanmaken betaling via Mollie. Problem creating Mollie payment.',
          'error'
        );
      }
    }
  };

  return (
    <div className={styles.root}>
      <header>
        <img src="/img/logo-gorsele.png" alt="logo" width="160" height="66" />
        <h1>Gorselé Payments - Betaling</h1>
      </header>
      <main className={styles.inner}>
        {!id && !paymentid && (
          <p className={styles.error}>
            Het betalingsid ontbreekt!
            <br />
            The payment id is missing!
          </p>
        )}
        {paymentDetails ? (
          <div>
            <h2>Order {paymentDetails.orderDetail.ordernr}</h2>
            <p>
              Route
              <br />
              {paymentDetails.orderDetail.route.routeString.full}
            </p>
            <p>Gelieve te betalen voor deze rit</p>

            <p>De totale prijs bedraagt €{paymentDetails.totalPrice}</p>

            {!paying ? (
              <button onClick={(e) => startPayment(e)} aria-label="betaling">
                Betaal nu deze rit
              </button>
            ) : null}
            <hr />
            <p>Please pay for this order</p>

            <p>The total price is €{paymentDetails.totalPrice}</p>

            {!paying ? (
              <button onClick={(e) => startPayment(e)} aria-label="payment">
                Pay order now
              </button>
            ) : null}
          </div>
        ) : null}
        {fetching ? <PulseLoader loading /> : null}
        {paying ? (
          <div className={styles.paying}>
            <p>
              Wachten op betaling via Mollie... Waiting for payment on Mollie...
            </p>
            <PulseLoader loading />
          </div>
        ) : null}
      </main>
      <footer>
        <img src="/img/methods.png" width="215" height="26" alt="methods" />
        Gorselé BVBA - Beverse Dijk 40, 9120 Kallo (BE) - BTW BE 0433.683.634
      </footer>
    </div>
  );
};

export default Home;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import styles from './home.module.scss';
import { PulseLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import { APIURI_PROD } from './config';
import { useSearchParams } from 'react-router-dom';

const PaymentComplete = () => {
  const [paymentDetails, setPaymentDetails] = useState();
  const [fetching, setFetching] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  let [paymentid, setPaymentid] = useState(searchParams.get('id'));
  let [mollie, setMollie] = useState(searchParams.get('mollie') || 0);

  const loadPaymentDetails = async (id) => {
    setFetching(true);
    const url = `${APIURI_PROD}/mollie/details/${id}`;
    const res = await fetch(url);
    const data = await res.json();
    setFetching(false);
    if (data?.success && data?.payment) {
      setPaymentDetails(data.payment);
    } else {
      Swal.fire(
        'Betaling. Payment.',
        'We konden geen rit vinden waarvoor een betaling nodig is. We could not find an order awaiting payment.',
        'info'
      );
    }
  };

  useEffect(() => {
    if (paymentid?.length) {
      loadPaymentDetails(paymentid);
    }
  }, [paymentid]);

  return (
    <div className={styles.root}>
      <header>
        <img src="/img/logo-gorsele.png" alt="logo" width="160" height="66" />
        <h1>Gorselé Payment Complete - Betaling Afgerond</h1>
      </header>
      <main className={styles.inner}>
        {!paymentid && !parseInt(mollie) ? (
          <p className={styles.error}>
            Het betalingsid ontbreekt!
            <br />
            The payment id is missing!
          </p>
        ) : null}
        <p>
          <strong>Bedankt voor uw betaling. Thank you for your payment.</strong>
        </p>
        {paymentDetails ? (
          <div>
            <h2>Order {paymentDetails.orderDetail.ordernr}</h2>
            <p>
              Route
              <br />
              {paymentDetails.orderDetail.route.routeString.full}
            </p>
          </div>
        ) : null}
        <p>
          <i>Je mag dit scherm sluiten. You can close this window.</i>
        </p>
        {fetching ? <PulseLoader loading /> : null}
      </main>
      <footer>
        Gorselé BVBA - Beverse Dijk 40, 9120 Kallo (BE) - BTW BE 0433.683.634
      </footer>
    </div>
  );
};

export default PaymentComplete;
